<template>
    <modal-lateral ref="modalFiltroComisiones" titulo="Filtro">
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 101px)">
            <div class="row mx-0 justify-center">
                <div class="col-10 mb-4">
                    <p class="f-14 text-general pl-3">Fecha de pago</p>
                    <el-date-picker
                    v-model="rangoFechas"
                    class="w-100"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    range-separator=""
                    placeholder="Fecha Inicio - Fecha fin"
                    />
                </div>
                <div class="col-10 mb-4">
                    <p class="f-14 text-general pl-3">Cedis</p>
                    <el-select v-model="id_cedis" filterable clearable class="w-100" @change="setCedis">
                        <el-option
                        v-for="item in selectCedis"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <div class="col-10 mb-4">
                    <p class="f-14 text-general pl-3">{{ $config.vendedor }}</p>
                    <el-select v-model="id_tienda" filterable clearable class="w-100" :disabled="id_cedis == ''">
                        <el-option
                        v-for="item in lecheros"
                        :key="item.id"
                        :label="item.propietario_nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <div class="col-10">
                    <p class="f-14 text-general pl-3">Encargado</p>
                    <el-select v-model="id_user" filterable clearable class="w-100">
                        <el-option
                        v-for="item in encargados"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
            </div>
        </div>
        <div class="row mx-0">
            <div class="col">
                <div class="bg-white cr-pointer text-general border-black d-middle-center br-8" style="height:32px;" @click="limpiar">
                    Limpiar
                </div>
            </div>
            <div class="col">
                <div class="bg-general cr-pointer text-white br-8 d-middle-center" style="height:32px;" @click="filtrar">
                    Filtrar
                </div>
            </div>
        </div>
    </modal-lateral>
</template>

<script>
import moment from 'moment'
import Liquidacion from '~/services/comisiones/liquidar'
export default {
    props:{
        registros:{
            type:Array,
            default: ()=>[]
        }
    },
    data(){
        return{
            rangoFechas:[],
            id_cedis: '',
            id_tienda:null,
            id_user:null,
            lecheros:[],
            encargados:[],
        }
    },
    computed:{
        selectCedis(){
            return this.cedis_calculo.filter(o=>o.id != 0)
        }
    },
    methods: {
        toggle(){
            this.getResponsables()
            this.$refs.modalFiltroComisiones.toggle();
        },
        setCedis(id){
            this.id_tienda = ''
            this.lecheros = []
            if(id){
                this.getLecheros()
            }
        },
        async getLecheros(){
            try {
                const {data} = await Liquidacion.getLecheros(this.id_cedis)
                this.lecheros = data.lecheros
            } catch (e){
                this.error_catch(e)
            }
        },
        async getResponsables(){
            try {
                const {data} = await Liquidacion.getResponsables()
                this.encargados = data.usuarios
            } catch (e){
                this.error_catch(e)
            }
        },
        filtrar(){
            let data = this.registros
            //console.log(data);
            if(this.rangoFechas != null){
                if(this.rangoFechas.length)
                    data = data.filter(i => moment(i.created_at).format('YYYY-MM-DD') >= this.rangoFechas[0] && moment(i.created_at).format('YYYY-MM-DD') <= this.rangoFechas[1])
            }
            if(this.id_cedis){
                data = data.filter(i => i.id_cedis == this.id_cedis)
            }
            if(this.id_tienda){
                data = data.filter(i => i.id_tienda == this.id_tienda)
            }
            if(this.id_user){
                data = data.filter(i => i.created_by == this.id_user)
            }
            //console.log(data);
            this.$emit('filtrar',data)
            this.$refs.modalFiltroComisiones.toggle();
        },
        limpiar(){
            this.rangoFechas = []
            this.id_cedis =  ''
            this.id_tienda = null
            this.id_user = null
            this.filtrar()
        }
    }
}
</script>
